<template>
  <fragment v-if="ready">
    <app-header title="Learning Plan" />

    <div class="container">
      <div class="alert bg-white text-primary mb-4">
        This learning plan was created by&nbsp;
        <router-link :to="{ name: 'users.single.details', params: { userId: learningPlan.creator_id } }" class="text-primary text-decoration-underline"> {{ learningPlan.creator_name }}</router-link>
      </div>

      <learning-plan-form
        :disabled="learningPlan.protected"
        @deleted="$router.replace({ name: 'my.learning-plans' })"
      />

      <hr class="my-5" />

      <div class="row mt-5 mb-3">
        <div class="col d-flex align-items-center">
          <h2 class="mb-0">Courses</h2>
        </div>
      </div>

      <div class="card">
        <div class="card-body">
          <div class="input-group">
            <div class="input-group-text"><i class="far fa-search"></i></div>
            <input type="text" placeholder="Search" class="form-control" @input="updateSearch" :value="filters.keywords" />
          </div>
        </div>
      </div>

      <c-data-list
        v-if="learningPlan.id"
        :items="courses"
        @load="loadCourses"
        class="results-list"
        remove-default-class
      >
        <template #default="{ item: course }">
          <course-card v-bind="{ course }">
            <template #bookmark>
              <c-button
                type="button"
                class="ms-1 btn btn-danger btn-remove"
                @click="remove(course)"
              >
                <i class="fad fa-trash-alt"></i>
              </c-button>
              <bookmark-button v-bind="{ course }" v-c-modal:addLearningPlan="{ course }" />
              <span class="like">
                <i class="fad fa-thumbs-up"></i>
                {{ course.likes_count }}
              </span>
            </template>
          </course-card>
        </template>
      </c-data-list>

      <add-learning-plan-modal
        ref="addLearningPlan"
        v-bind="{ courses }"
        @updated="setCourses"
        @close="removeRemovedCourses"
      />
    </div>
  </fragment>
</template>

<script>
import { get, call } from 'vuex-pathify'
import { useQueryParamFilters, compareQueryParams } from '@codium/codium-vue'
import { debounce } from 'lodash'
import LearningPlanForm from '@/components/learning-plans/LearningPlanForm.vue'
import CourseCard from '@/components/courses/admin/CourseCard.vue'
import AddLearningPlanModal from '@/components/courses/AddLearningPlanModal.vue'
import BookmarkButton from '@/components/learning-plans/BookmarkButton.vue'

export default {
  mixins: [useQueryParamFilters()],

  props: {
    learningPlanId: [Number, String]
  },

  components: {
    LearningPlanForm,
    CourseCard,
    AddLearningPlanModal,
    BookmarkButton,
  },

  data () {
    return {
      ready: false,
      filters: {
        keywords: null
      }
    }
  },

  computed: {
    learningPlan: get('learningPlans/learningPlan'),
    courses: get('learningPlans/courses/courses')
  },

  watch: {
    queryParams: compareQueryParams(async function (params) {
      await this.getCourses({ params, fresh: true })
    })
  },

  beforeDestroy () {
    this.resetCourses()
  },

  async created () {
    const { learningPlanId } = this

    await this.getLearningPlan(learningPlanId)

    this.ready = true
  },

  methods: {
    getLearningPlan: call('learningPlans/getLearningPlan'),
    getCourses: call('learningPlans/courses/getCourses'),
    resetCourses: call('learningPlans/courses/resetCourses'),
    setCourses: call('learningPlans/courses/setCourses'),
    removeCourse: call('learningPlans/courses/removeCourse'),
    deleteLearningPlanCourse: call('learningPlans/courses/deleteLearningPlanCourse'),

    updateSearch: debounce(function (event) {
      this.filters.keywords = event.target.value
    }, 500),

    async removeRemovedCourses () {
      this.courses.forEach(async (course) => {
        if (course.user_learning_plans.includes(this.learningPlan.id) === false) {
          await this.removeCourse(course)
        }
      })
    },

    async remove (course) {
      await this.deleteLearningPlanCourse({ id: this.learningPlan.id, courseId: course.id })

      await this.removeCourse(course)
    },

    async loadCourses (done) {
      try {
        await this.getCourses({ params: this.queryParams })

        done()
      } catch {
        done(true)
      }
    }
  }
}
</script>

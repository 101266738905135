<template>
  <fragment>
    <app-header title="Learning Plans" />

    <div class="layout-sidesheet row">
      <div class="col main-content">
        <vue-scroll>
          <div class="container">
            <div class="row">
              <div class="col d-flex align-items-center">
                <strong>Search Results</strong>
              </div>
              <div class="col-auto">
                <div class="row">
                  <label class="col-auto col-form-label">Sort By</label>
                  <div class="col">
                    <multiselect
                      class="multiselect-sort-by-horizontal"
                      placeholder="Select"
                      label="label"
                      :preselect-first="true"
                      :show-labels="false"
                      :options="sortOptions"
                      v-model="sortSelected"
                    />
                  </div>
                </div>
                <!-- /.row -->
              </div>
            </div>
            <!-- /.row -->

            <c-data-list
              v-if="!loading"
              :items="learningPlans"
              @load="load"
              class="results-list"
              target-vue-scroll
              remove-default-class
            >
              <template #default="{ item: learningPlan }">
                <learning-plan-card
                  v-bind="{ learningPlan }"
                  hide-visibility
                  always-show-creator
                />
              </template>
            </c-data-list>
          </div>

          <slot name="footer" />
        </vue-scroll>
      </div>
      <div
        class="col-xl-4 col-xxl-3 side-sheet side-sheet-filters overflow-auto"
      >
        <div class="sidesheet-inner overflow-auto">
          <vue-scroll>
            <div class="card-body">
              <h2>Search Filters</h2>

              <button type="button" class="btn btn-sidesheet-show" @click="toggleSidebar">
                <i class="far fa-times"></i>
              </button>

              <div class="search-filter">
                <div class="row">
                  <div class="col-auto d-flex align-items-end">
                    <i class="filter-icon fal fa-fw fa-search"></i>
                  </div>
                  <div class="col">
                    <label class="form-label">Keywords</label>
                    <c-input
                      :value="filters.keywords"
                      @input="updateKeywordsFilter"
                    />
                  </div>
                </div>
              </div>

              <div class="search-filter">
                <div class="row">
                  <div class="col-auto d-flex align-items-end">
                    <i class="filter-icon fal fa-fw fa-user-tie"></i>
                  </div>
                  <div class="col mb-1">
                    <div class="row">
                      <div class="col d-flex align-items-center">
                        <p class="form-label">Professions</p>
                      </div>
                      <div class="col-auto">
                        <button
                          type="button"
                          class="btn btn-topic-select btn-outline-primary"
                          v-c-modal:professions
                        >
                          Select
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="selection">
                  <div class="chip chip-primary ms-1" v-for="({ id, name }, index) in professionNames" :key="`profession-${index}`">
                    <span class="text-truncate">{{ name }}</span>
                    <button type="button" class="remove-chip" @click="removeSelectedProfession(id)">
                      <i class="far fa-times"></i>
                    </button>
                  </div>
                </div>
              </div>

              <div class="search-filter">
                <div class="row">
                  <div class="col-auto d-flex align-items-end">
                    <i class="filter-icon fal fa-fw fa-hospital-user"></i>
                  </div>
                  <div class="col mb-1">
                    <div class="row">
                      <div class="col d-flex align-items-center">
                        <p class="form-label">Primary Fields</p>
                      </div>
                      <div class="col-auto">
                        <button
                          type="button"
                          class="btn btn-topic-select btn-outline-primary"
                          v-c-modal:primaryFields
                          :disabled="!filters.professions.length"
                        >
                          Select
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="selection">
                  <div
                    class="chip chip-primary ms-1"
                    v-for="({ id, name }, index) in primaryFieldNames"
                    :key="`primary-field-${index}`"
                  >
                    <span class="text-truncate">{{ name }}</span>
                    <button type="button" class="remove-chip" @click="removeSelectedPrimaryField(id)">
                      <i class="far fa-times"></i>
                    </button>
                  </div>
                </div>
              </div>

              <div class="search-filter">
                <div class="row">
                  <div class="col-auto d-flex align-items-end">
                    <i class="filter-icon fal fa-fw fa-books-medical"></i>
                  </div>
                  <div class="col mb-1">
                    <div class="row">
                      <div class="col d-flex align-items-center">
                        <p class="form-label">Topics</p>
                      </div>
                      <div class="col-auto">
                        <button
                          type="button"
                          class="btn btn-topic-select btn-outline-primary"
                          v-c-modal:topics
                          :disabled="!filters.professions.length"
                        >
                          Select
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="selection">
                  <div class="chip chip-primary ms-1" v-for="({ id, name }, index) in topicNames" :key="`topic-${index}`">
                    <span class="text-truncate">{{ name }}</span>
                    <button type="button" class="remove-chip" @click="removeSelectedTopic(id)">
                      <i class="far fa-times"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </vue-scroll>
        </div>
      </div>
    </div>

    <select-professions-drawer v-model="professionsSelected" ref="professions"/>

    <select-primary-fields-drawer
      v-model="primaryFieldsSelected"
      ref="primaryFields"
      :professions="filters.professions"
    />

    <select-topics-drawer
      v-model="topicsSelected"
      ref="topics"
      :professions="filters.professions"
    />

    <portal to="headerRight">
      <ul class="header-nav justify-content-end">
        <li>
          <button
            type="button"
            class="btn btn-sidesheet-show search navbar-user-link d-xl-none"
            @click="toggleSidebar"
          >
            <i class="fal fa-search"></i> Search
          </button>
        </li>
      </ul>
    </portal>

  </fragment>
</template>

<script>
import { useQueryParamFilters, compareQueryParams } from '@codium/codium-vue'
import { get, call } from 'vuex-pathify'
import { debounce, toNumber } from 'lodash'
import LearningPlanCard from '@/components/learning-plans/LearningPlanCard.vue'
import SelectProfessionsDrawer from '@/components/professions/SelectProfessionsDrawer.vue'
import SelectTopicsDrawer from '@/components/topics/SelectTopicsDrawer.vue'
import SelectPrimaryFieldsDrawer from '@/components/primary-fields/SelectPrimaryFieldsDrawer.vue'

export default {
  mixins: [useQueryParamFilters()],

  components: {
    LearningPlanCard,
    SelectProfessionsDrawer,
    SelectTopicsDrawer,
    SelectPrimaryFieldsDrawer,
  },

  data () {
    return {
      loading: true,
      filters: {
        keywords: null,
        professions: [],
        topics: [],
        primaryFields: [],
        sort: 'votes',
        order: 'desc'
      },
      sortOptions: [
        { label: 'Votes (High - Low)', value: { sort: 'votes', order: 'desc' } },
        { label: 'Votes (Low - High)', value: { sort: 'votes', order: 'asc' } },
        { label: 'Newest First', value: { sort: 'date', order: 'desc' } },
        { label: 'Oldest First', value: { sort: 'date', order: 'asc' } },
        { label: 'Alphabetical A-Z', value: { sort: 'name', order: 'asc' } },
        { label: 'Alphabetical Z-A', value: { sort: 'name', order: 'desc' } },
      ]
    }
  },

  watch: {
    queryParams: compareQueryParams(async function (params) {
      await this.getLearningPlans({ params, fresh: true })
    })
  },

  computed: {
    professions: get('lists/professions/professions'),
    topics: get('lists/topics/topics'),
    primaryFields: get('lists/primaryFields/primaryFields'),
    learningPlans: get('learningPlans/learningPlans'),

    professionNames () {
      return this.professions
        .filter(({ id }) => this.professionsSelected.includes(id))
    },

    topicNames () {
      return this.topics
        .reduce((topics, topic) => [...topics, topic, ...topic.children], [])
        .filter(({ id }) => this.topicsSelected.includes(id))
    },

    primaryFieldNames () {
      return this.primaryFields
        .filter(({ id }) => this.primaryFieldsSelected.includes(id))
    },

    sortSelected: {
      get () {
        return this.sortOptions.find(({ value }) => value.sort === this.filters.sort && value.order === this.filters.order)
      },

      set ({ value }) {
        this.filters.sort = value.sort
        this.filters.order = value.order
      }
    },

    professionsSelected: {
      get () {
        return this.filters.professions.map(toNumber)
      },

      set (value) {
        this.filters.professions = value
      }
    },

    topicsSelected: {
      get () {
        return this.filters.topics.map(toNumber)
      },

      set (value) {
        this.filters.topics = value
      }
    },

    primaryFieldsSelected: {
      get () {
        return this.filters.primaryFields.map(toNumber)
      },

      set (value) {
        this.filters.primaryFields = value
      }
    },
  },

  async created () {
    await this.getProfessions().catch(() => {})
    await this.getTopics().catch(() => {})

    if (!this.$route.query.professions?.length) {
      this.professionsSelected = [this.$user.profession_id]
    }

    this.loading = false
  },

  beforeDestroy () {
    this.resetLearningPlans()
  },

  methods: {
    resetLearningPlans: call('learningPlans/resetLearningPlans'),
    getLearningPlans: call('learningPlans/getLearningPlans'),
    getProfessions: call('lists/professions/getProfessions'),
    getTopics: call('lists/topics/getTopics'),

    updateKeywordsFilter: debounce(function (value) {
      this.filters.keywords = value
    }, 500),

    removeSelectedProfession (id) {
      const professions = [...this.professionsSelected]
      const index = professions.indexOf(id)

      professions.splice(index, 1)

      this.professionsSelected = professions
    },

    removeSelectedTopic (id) {
      const topics = [...this.topicsSelected]
      const index = topics.indexOf(id)

      topics.splice(index, 1)

      this.topicsSelected = topics
    },

    removeSelectedPrimaryField (id) {
      const primaryFields = [...this.primaryFieldsSelected]
      const index = primaryFields.indexOf(id)

      primaryFields.splice(index, 1)

      this.primaryFieldsSelected = primaryFields
    },

    async load (done) {
      try {
        await this.getLearningPlans({ params: this.queryParams })

        done()
      } catch {
        done(true)
      }
    },

    toggleSidebar () {
      document.querySelector('.side-sheet').classList.toggle('show')
    }
  }
}
</script>

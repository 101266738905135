var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('fragment',[_c('app-header',{attrs:{"title":"Learning Plans"}}),_c('div',{staticClass:"layout-sidesheet row"},[_c('div',{staticClass:"col main-content"},[_c('vue-scroll',[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col d-flex align-items-center"},[_c('strong',[_vm._v("Search Results")])]),_c('div',{staticClass:"col-auto"},[_c('div',{staticClass:"row"},[_c('label',{staticClass:"col-auto col-form-label"},[_vm._v("Sort By")]),_c('div',{staticClass:"col"},[_c('multiselect',{staticClass:"multiselect-sort-by-horizontal",attrs:{"placeholder":"Select","label":"label","preselect-first":true,"show-labels":false,"options":_vm.sortOptions},model:{value:(_vm.sortSelected),callback:function ($$v) {_vm.sortSelected=$$v},expression:"sortSelected"}})],1)])])]),(!_vm.loading)?_c('c-data-list',{staticClass:"results-list",attrs:{"items":_vm.learningPlans,"target-vue-scroll":"","remove-default-class":""},on:{"load":_vm.load},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var learningPlan = ref.item;
return [_c('learning-plan-card',_vm._b({attrs:{"hide-visibility":"","always-show-creator":""}},'learning-plan-card',{ learningPlan: learningPlan },false))]}}],null,false,2524136082)}):_vm._e()],1),_vm._t("footer")],2)],1),_c('div',{staticClass:"col-xl-4 col-xxl-3 side-sheet side-sheet-filters overflow-auto"},[_c('div',{staticClass:"sidesheet-inner overflow-auto"},[_c('vue-scroll',[_c('div',{staticClass:"card-body"},[_c('h2',[_vm._v("Search Filters")]),_c('button',{staticClass:"btn btn-sidesheet-show",attrs:{"type":"button"},on:{"click":_vm.toggleSidebar}},[_c('i',{staticClass:"far fa-times"})]),_c('div',{staticClass:"search-filter"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-auto d-flex align-items-end"},[_c('i',{staticClass:"filter-icon fal fa-fw fa-search"})]),_c('div',{staticClass:"col"},[_c('label',{staticClass:"form-label"},[_vm._v("Keywords")]),_c('c-input',{attrs:{"value":_vm.filters.keywords},on:{"input":_vm.updateKeywordsFilter}})],1)])]),_c('div',{staticClass:"search-filter"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-auto d-flex align-items-end"},[_c('i',{staticClass:"filter-icon fal fa-fw fa-user-tie"})]),_c('div',{staticClass:"col mb-1"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col d-flex align-items-center"},[_c('p',{staticClass:"form-label"},[_vm._v("Professions")])]),_c('div',{staticClass:"col-auto"},[_c('button',{directives:[{name:"c-modal",rawName:"v-c-modal:professions",arg:"professions"}],staticClass:"btn btn-topic-select btn-outline-primary",attrs:{"type":"button"}},[_vm._v(" Select ")])])])])]),_c('div',{staticClass:"selection"},_vm._l((_vm.professionNames),function(ref,index){
var id = ref.id;
var name = ref.name;
return _c('div',{key:("profession-" + index),staticClass:"chip chip-primary ms-1"},[_c('span',{staticClass:"text-truncate"},[_vm._v(_vm._s(name))]),_c('button',{staticClass:"remove-chip",attrs:{"type":"button"},on:{"click":function($event){return _vm.removeSelectedProfession(id)}}},[_c('i',{staticClass:"far fa-times"})])])}),0)]),_c('div',{staticClass:"search-filter"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-auto d-flex align-items-end"},[_c('i',{staticClass:"filter-icon fal fa-fw fa-hospital-user"})]),_c('div',{staticClass:"col mb-1"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col d-flex align-items-center"},[_c('p',{staticClass:"form-label"},[_vm._v("Primary Fields")])]),_c('div',{staticClass:"col-auto"},[_c('button',{directives:[{name:"c-modal",rawName:"v-c-modal:primaryFields",arg:"primaryFields"}],staticClass:"btn btn-topic-select btn-outline-primary",attrs:{"type":"button","disabled":!_vm.filters.professions.length}},[_vm._v(" Select ")])])])])]),_c('div',{staticClass:"selection"},_vm._l((_vm.primaryFieldNames),function(ref,index){
var id = ref.id;
var name = ref.name;
return _c('div',{key:("primary-field-" + index),staticClass:"chip chip-primary ms-1"},[_c('span',{staticClass:"text-truncate"},[_vm._v(_vm._s(name))]),_c('button',{staticClass:"remove-chip",attrs:{"type":"button"},on:{"click":function($event){return _vm.removeSelectedPrimaryField(id)}}},[_c('i',{staticClass:"far fa-times"})])])}),0)]),_c('div',{staticClass:"search-filter"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-auto d-flex align-items-end"},[_c('i',{staticClass:"filter-icon fal fa-fw fa-books-medical"})]),_c('div',{staticClass:"col mb-1"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col d-flex align-items-center"},[_c('p',{staticClass:"form-label"},[_vm._v("Topics")])]),_c('div',{staticClass:"col-auto"},[_c('button',{directives:[{name:"c-modal",rawName:"v-c-modal:topics",arg:"topics"}],staticClass:"btn btn-topic-select btn-outline-primary",attrs:{"type":"button","disabled":!_vm.filters.professions.length}},[_vm._v(" Select ")])])])])]),_c('div',{staticClass:"selection"},_vm._l((_vm.topicNames),function(ref,index){
var id = ref.id;
var name = ref.name;
return _c('div',{key:("topic-" + index),staticClass:"chip chip-primary ms-1"},[_c('span',{staticClass:"text-truncate"},[_vm._v(_vm._s(name))]),_c('button',{staticClass:"remove-chip",attrs:{"type":"button"},on:{"click":function($event){return _vm.removeSelectedTopic(id)}}},[_c('i',{staticClass:"far fa-times"})])])}),0)])])])],1)])]),_c('select-professions-drawer',{ref:"professions",model:{value:(_vm.professionsSelected),callback:function ($$v) {_vm.professionsSelected=$$v},expression:"professionsSelected"}}),_c('select-primary-fields-drawer',{ref:"primaryFields",attrs:{"professions":_vm.filters.professions},model:{value:(_vm.primaryFieldsSelected),callback:function ($$v) {_vm.primaryFieldsSelected=$$v},expression:"primaryFieldsSelected"}}),_c('select-topics-drawer',{ref:"topics",attrs:{"professions":_vm.filters.professions},model:{value:(_vm.topicsSelected),callback:function ($$v) {_vm.topicsSelected=$$v},expression:"topicsSelected"}}),_c('portal',{attrs:{"to":"headerRight"}},[_c('ul',{staticClass:"header-nav justify-content-end"},[_c('li',[_c('button',{staticClass:"btn btn-sidesheet-show search navbar-user-link d-xl-none",attrs:{"type":"button"},on:{"click":_vm.toggleSidebar}},[_c('i',{staticClass:"fal fa-search"}),_vm._v(" Search ")])])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }
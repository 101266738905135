<template>
  <fragment>
    <app-header title="Learning Plans" />

    <div class="container">
      <div class="mb-4">
        <a href="" class="text-primary" @click.prevent="$router.go(-1)">
          <i class="far fa-chevron-left me-2"></i> Back
        </a>
      </div>

      <article>
        <div class="row mb-4">
          <div class="col d-flex align-items-center">
            <h1 class="h2 mb-0">{{ learningPlan.name }}</h1>
          </div>
          <div class="col-auto">
            <ul class="learning-plan-actions">
              <li>
                <button
                  class="btn"
                  :class="{ active: learningPlan.following }"
                  title="Following"
                  v-c-tooltip.hover
                  @click="followOrUnfollow"
                >
                  <i class="fad fa-calendar-star fa-fw"></i> {{ learningPlan.followers_count }}
                </button>
              </li>
              <li>
                <button
                  class="btn"
                  :class="{ active: learningPlan.liked }"
                  title="Likes"
                  v-c-tooltip.hover
                  @click="likeOrUnlike"
                >
                  <i class="fad fa-thumbs-up fa-fw"></i> {{ learningPlan.likes_count }}
                </button>
              </li>
            </ul>
          </div>
        </div>

        <div class="card">
          <div class="card-body card-body-lg">
            <footer>
              <p class="text-muted d-inline-block pe-3">Created by: {{ learningPlan.creator_name }}</p>
              <p class="text-muted d-inline-block">Last updated: {{ learningPlan.updated_time_ago }}</p>
            </footer>

            <footer>
              <span
                v-for="profession in learningPlan.professions"
                :key="`profession-${profession.id}`"
                class="chip chip-primary mb-3"
              >
                {{ profession.name }}
              </span>
            </footer>

            <div v-html="learningPlan.description"/>

            <ul class="tag-list">
              <li v-for="topic in learningPlan.topics" :key="topic.id" class="me-2">
                <span class="tag">{{ topic.name }}</span>
              </li>
            </ul>
          </div>
        </div>

        <hr class="my-5" />

        <c-data-list
          v-if="learningPlan.id"
          :items="courses"
          @load="loadCourses"
          class="results-list"
          remove-default-class
        >
          <template #default="{ item: course }">
            <course-card v-bind="{ course }">
              <template #bookmark>
                <bookmark-button v-bind="{ course }" v-c-modal:addLearningPlan="{ course }" />
                <span class="like">
                  <i class="fad fa-thumbs-up"></i>
                  {{ course.likes_count }}
                </span>
              </template>
            </course-card>
          </template>
        </c-data-list>
      </article>
    </div>

    <add-learning-plan-modal
      ref="addLearningPlan"
      v-bind="{ courses }"
      @updated="setCourses"
    />
  </fragment>
</template>

<script>
import { get, call, sync } from 'vuex-pathify'
import { http } from '@/utils'
import { toast } from '@chameleon/chameleon-vue'
import AddLearningPlanModal from '@/components/courses/AddLearningPlanModal.vue'
import CourseCard from '@/components/courses/user/CourseCard.vue'
import BookmarkButton from '@/components/learning-plans/BookmarkButton.vue'

export default {
  props: {
    learningPlanId: Number
  },

  components: {
    CourseCard,
    BookmarkButton,
    AddLearningPlanModal
  },

  computed: {
    learningPlan: get('learningPlans/learningPlan'),
    courses: get('learningPlans/courses/courses'),
    following: sync('learningPlans/learningPlan@following'),
    followersCount: sync('learningPlans/learningPlan@followers_count'),
    liked: sync('learningPlans/learningPlan@liked'),
    likesCount: sync('learningPlans/learningPlan@likes_count'),
  },

  async created () {
    await this.getLearningPlan(this.learningPlanId)
  },

  beforeDestroy () {
    this.resetCourses()
    this.resetLearningPlan()
  },

  methods: {
    getLearningPlan: call('learningPlans/getLearningPlan'),
    resetLearningPlan: call('learningPlans/resetLearningPlan'),
    getCourses: call('learningPlans/courses/getCourses'),
    setCourses: call('learningPlans/courses/setCourses'),
    resetCourses: call('learningPlans/courses/resetCourses'),

    async followOrUnfollow () {
      try {
        await http.put(`learning-plans/${this.learningPlanId}/follow`)

        this.following = !this.following

        this.followersCount = this.followersCount + (this.following ? 1 : -1)
      } catch {
        toast('Something went wrong when trying to follow or unfollow the learning plan.', 'danger')
      }
    },

    async likeOrUnlike () {
      try {
        await http.put(`learning-plans/${this.learningPlanId}/like`)

        this.liked = !this.liked

        this.likesCount = this.likesCount + (this.liked ? 1 : -1)
      } catch {
        toast('Something went wrong when trying to like or unlike the learning plan.', 'danger')
      }
    },

    async loadCourses (done) {
      try {
        await this.getCourses()

        done()
      } catch {
        done(true)
      }
    }
  }
}
</script>

<template>
  <fragment>
    <app-header title="Learning Plans" />
    <div class="container container-max-md d-flex flex-column flex-grow-1 justify-content-center mt-4">
      <div>
        <h1>Premium Feature.</h1>

        <p class="lead">
          A premium Med CPD subscription is required to manage your learning plans.
        </p>

        <p class="text-muted">
          Click below to explore all of Med CPD's subscription plans and find an experience that works for you.
        </p>

        <router-link :to="{ name: 'my.subscription' }" class="btn btn-primary mt-4">
          Subscribe
        </router-link>
      </div>
    </div>
    <slot name="footer" />
  </fragment>
</template>

<script>
export default {
}
</script>
